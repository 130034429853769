<template>
    <div class="row m-0 px-0 pt-7">
        <div class="col-12 p-0 m-0">
            <div class="section-one w-100 pt-10 pt-4 mb-0 mb-lg-4">
                <div class="row justify-content-center min-vh-100">
                    <div class="col-10 p-0">
                        <div class="row justify-content-end mb-4">
                            <div class="col-lg-4">
                                <input type="text" class="form-control w-100" @change="getArticle(page)" v-model="search" placeholder="Search here">
                            </div>
                        </div>
                        <div class="row" v-if="!isLoad">
                            <div class="col-12">
                                <div class="row" v-if="articles.data.length > 0">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-lg-3" v-for="(item, index) in articles.data" :key="index">
                                                <img :src=" item.thumbnail" class="mb-2" width="100%" alt="">
                                                <router-link :to="{name: 'BlogDetailBrowseNew', params: {idBlog: item.id}}" class="fw-700 text-black font-xss">{{item.title}}</router-link>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <ul class="pagination pagination-center justify-content-center d-flex pt-5">
                                                    <li v-for="(link, index) in articles.links" :key="index" class="page-item m-1">
                                                        <button v-if="link.label === '&laquo; Previous'" v-bind:class="{'bg-current text-white' : link.active === true, 'bg-white text-current' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-700 font-xssss shadow-xss border-1" :disabled="link.url === null" @click="prevPage()"><i class="ti-angle-left"></i></button>
                                                        <button v-else-if="link.label === 'Next &raquo;'" v-bind:class="{'bg-current text-white' : link.active === true, 'bg-white text-current' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-700 font-xssss shadow-xss border-1" :disabled="link.url === null" @click="nextPage()"><i class="ti-angle-right"></i></button>
                                                        <button v-else v-bind:class="{'bg-current text-white' : link.active === true, 'bg-white text-current' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-700 font-xssss shadow-xss border-1" @click="changePage(link.label)">{{link.label}}</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-else>
                                    <div class="col-12 text-center py-3">
                                        <img :src="'/images/empty.png'" alt="" width="300">
                                        <h1 class="text-muted">Data Empty</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-12 text-center py-3">
                                <div class="my-3">
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-12"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data(){
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            page: 1,
            search: '',
            articles: {
                data: [],
                links: [],
                last_page: 0
            }
        }
    },
    created(){
        if(this.$route.query.search) {
            this.search = this.$route.query.search
        }
        this.getArticle(this.page);
    },
    methods: {
        async getArticle(page){
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/blog/article?page=${parseInt(page)}&search=${this.search.toLowerCase().trim()}`)
            .then(res => {
                this.articles = res.data.data
            }).then(() => this.isLoad = false)
        },
        changePage(page) {
            this.page = parseInt(page)
            this.getArticle(this.page)
        },
        prevPage() {
            if (this.page >= 1) {
                this.page -= 1
                this.getArticle(this.page)
            }
        },
        nextPage() {
            if (this.articles.last_page > this.page) {
                this.page += 1
                this.getArticle(this.page)
            }
        },
    }
}
</script>